<template lang="pug">
div(ref="calendarContainer")
  .meetings-iframe-container(:data-src="calendarUrl")
</template>

<script>
  export default {
    props: {
      calendarUrl: {
        type: String,
        required: true,
      },
    },
    mounted() {
      const script = document.createElement('script');
      script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
      script.async = true;
      script.defer = true;
      this.$refs.calendarContainer.appendChild(script);
    },
  };
</script>
